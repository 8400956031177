export enum GameGroups {
  lotto = 0,
  ghana = 1,
  guinea = 2,
}

export enum CancelReasons {
  notEnoughMoney = 100,
  other = 500,
}

export enum DrawStatuses {
  cancelled = 10,
  closed = 20,
  suspended = 30,
  active = 100,
  finishedResultRegistered = 105,
}

// Combi Tickets have string values instead of numbers. In the commends on the right there is string value for COMBI
export enum TicketStatuses {
  accepted = 100, // ACCEPTED
  resulted = 200, // RESULTED
  settled = 300, // SETTLED
  cancelledByCashier = 400, // CANCELLED_BY_CASHIER
  cancelledByAdmin = 500, //  CANCELLED_BY_ADMIN
  lapsed = 600, // LAPSED
}

export enum OutcomeStatuses {
  new = 0,
  won = 1,
  lost = 2,
  firstSetLost = 3,
}

export enum CombiOurcomeStatuses {
  won = 'BET_WON',
  lost = 'BET_LOST',
}

// Distinction between tickets is encrypted and the correct ticket is selected based on this. This is done because we don't want to have separate endpoints.
export enum TicketEncryptedType {
  STANDARD = 1,
  COMBI = 2,
}

export enum CombiTicketStatuses {
  'accepted' = 'ACCEPTED',
  'resulted' = 'RESULTED',
  'settled' = 'SETTLED',
  'cancelledByCashier' = 'CANCELLED_BY_CASHIER',
  'cancelledByAdmin' = 'CANCELLED_BY_ADMIN',
  'lapsed' = 'LAPSED',
}
