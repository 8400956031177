import { FC, lazy, ReactNode, StrictMode, Suspense, useEffect, useMemo, useState } from 'react'
import { QueryClientProvider } from 'react-query'
import Cookies from 'js-cookie'
import preval from 'preval.macro'
import './index.css'
import { queryClient } from 'query'
import { Spinner } from 'components/spinner'
import { useBrandsQuery } from 'api/brands'
import { createRoot } from 'react-dom/client'

document.body.dataset.buildTimestamp = preval`module.exports = new Date().toUTCString();`
document.body.dataset.commitHash = preval`module.exports =  require('child_process').execSync('git rev-parse --short HEAD').toString().trim();`
document.body.dataset.version = preval`module.exports = require('../package.json').version`

const LazyWelcomePage = lazy(() => import('components/welcome-page'))
const LazyApp = lazy(() => import('components/app'))

const AppOrWelcomePage: FC = () => {
  const { data: brands, isLoading } = useBrandsQuery()

  let params = new URLSearchParams(document.location.search)
  let affiliateId

  for (const [key, value] of params.entries()) {
    if (key.toLowerCase() === 'affiliateid') {
      affiliateId = value
      break
    }
  }

  if (affiliateId) {
    Cookies.set('netReferAffiliateId', affiliateId, { expires: 365 })
  }

  const hasMatchingBrandRouteValue = useMemo(() => {
    if (!brands) return null

    return brands.some(
      (brand) => brand.routingType === 'domain' && brand.routingValue === window.location.origin,
    )
  }, [brands])

  if (!brands) {
    return isLoading ? (
      <div
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          display: 'flex',
        }}
      >
        <Spinner />
      </div>
    ) : null
  }

  return (
    <Suspense fallback={<Spinner />}>
      {!hasMatchingBrandRouteValue && window.location.pathname === '/' ? (
        <LazyWelcomePage />
      ) : (
        <LazyApp />
      )}
    </Suspense>
  )
}

// Conditional polyfill for very old devices used in Africa.
const PolyfillWrapper: FC<{ children: ReactNode }> = ({ children }) => {
  const [init, setInit] = useState(false)

  useEffect(() => {
    if ('flat' in [] && 'replaceAll' in String.prototype) {
      setInit(true)
    } else {
      // @ts-ignore
      import('core-js/actual').then(() => {
        setInit(true)
      })
    }
  }, [])

  if (!init) return null

  return <>{children}</>
}

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <PolyfillWrapper>
        <AppOrWelcomePage />
      </PolyfillWrapper>
    </QueryClientProvider>
  </StrictMode>,
)
