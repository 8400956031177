import { GameGroups } from './ticket'

export const hideToasts = !!process.env.REACT_APP_HIDE_TOASTS
export const adultAge = process.env.REACT_APP_ADULT_AGE || 18

export const couponPrefix = '00'

export const maxCouponNumberLength = 19

export const ticketCancelReasonMaxLength = 155

export const redZoneBrandIds = [5, 6, 7, 13, 14]
export const greenZoneBrandIds: number[] = []
export const blueZoneBrandIds: number[] = []

type BrandInfo = {
  brandId: number
  countryCode: string
  nativeName: string
  released: boolean
  showTax: boolean
  excluded?: boolean
  customTitle?: string
  customDescription?: string
  isBetInShopDisabled?: boolean
  redirectToResultsPage?: boolean
  hideTicketOnPage?: boolean
  loginAvailable?: boolean
  customLogo?: boolean
  loginButtonTicket?: boolean
  disabledPayAndPlay?: boolean
}

export const brandsInfo: BrandInfo[] = [
  {
    brandId: 6,
    countryCode: 'CM',
    nativeName: 'Cameroun',
    released: true,
    showTax: false,
    customTitle: 'Loto',
    customDescription: 'Loto Premierbet',
    loginAvailable: true,
    loginButtonTicket: true,
    disabledPayAndPlay: false,
  },
  {
    brandId: 2,
    countryCode: 'GH',
    nativeName: 'Ghana',
    released: false,
    excluded: true,
    showTax: false,
  },
  {
    brandId: 13,
    countryCode: 'MZ',
    nativeName: 'Moçambique',
    released: true,
    showTax: true,
  },
  {
    brandId: 5,
    countryCode: 'CG',
    nativeName: 'Brazzaville',
    released: true,
    showTax: false,
    customTitle: 'Loto',
    customDescription: 'Loto Premierbet',
  },
  {
    brandId: 19,
    countryCode: 'GN',
    nativeName: 'Guinée',
    released: true,
    excluded: false,
    showTax: false,
    customTitle: 'Loto',
    customDescription: 'Guinee Loto Zone',
    loginAvailable: true,
    isBetInShopDisabled: false,
    customLogo: true,
    loginButtonTicket: true,
  },
  {
    brandId: 16,
    countryCode: 'NG',
    nativeName: 'Nigeria',
    released: false,
    excluded: true,
    showTax: false,
  },
  {
    brandId: 21,
    countryCode: 'NG',
    nativeName: 'Nigeria',
    released: false,
    excluded: true,
    showTax: false,
  },
  {
    brandId: 4,
    countryCode: 'CD',
    nativeName: 'RD Congo',
    released: true,
    showTax: false,
  },
  {
    brandId: 7,
    countryCode: 'MW',
    nativeName: 'Malawi',
    released: true,
    showTax: false,
  },
  {
    brandId: 15,
    countryCode: 'RW',
    nativeName: 'Rwanda',
    released: false,
    excluded: true,
    showTax: false,
  },
  {
    brandId: 12,
    countryCode: 'GA',
    nativeName: 'Gabon',
    released: false,
    excluded: true,
    showTax: false,
  },
  {
    brandId: 8,
    countryCode: 'ML',
    nativeName: 'Mali',
    released: true,
    showTax: false,
    isBetInShopDisabled: false,
  },
  {
    brandId: 9,
    countryCode: 'SN',
    nativeName: 'Sénégal',
    released: false,
    excluded: false,
    showTax: false,
    isBetInShopDisabled: true,
    redirectToResultsPage: true,
    hideTicketOnPage: true,
    customLogo: true,
  },
  {
    brandId: 18,
    countryCode: 'SL',
    nativeName: 'Sierra Leone',
    released: false,
    showTax: true,
    isBetInShopDisabled: true,
  },
  {
    brandId: 10,
    countryCode: 'TZ',
    nativeName: 'Tanzania',
    released: false,
    excluded: true,
    showTax: false,
  },
  {
    brandId: 14,
    countryCode: 'TD',
    nativeName: 'Tchad',
    released: true,
    showTax: false,
    customTitle: 'Loto',
    customDescription: 'Loto Premierbet',
  },
  {
    brandId: 1,
    countryCode: 'UG',
    nativeName: 'Uganda',
    released: false,
    excluded: true,
    showTax: false,
  },
  {
    brandId: 17,
    countryCode: 'AO',
    nativeName: 'Angola',
    released: false,
    excluded: true,
    showTax: false,
  },
  {
    brandId: 3,
    countryCode: 'KE',
    nativeName: 'Kenya',
    released: false,
    excluded: true,
    showTax: false,
  },
  {
    brandId: 11,
    countryCode: 'ZM',
    nativeName: 'Zambia',
    released: false,
    excluded: true,
    showTax: false,
  },
  {
    brandId: 23,
    countryCode: 'TG',
    nativeName: 'Togo',
    released: false,
    excluded: false,
    showTax: false,
  },
]

export const guineaContextDetails = {
  brandId: 19,
  drawGroupId: 7,
  gameGroup: GameGroups.guinea,
}

export const sierraLeoneContextDetails = {
  brandId: 18,
}

export const maliContextDetails = {
  brandId: 8,
}

export const cameroonContextDetails = {
  brandId: 6,
}

export const mozambiqueTaxRate = 0.15

export const maxTextInputLength = 155

export const desktopAppPortKey = 'lotto-desktop-app-port'

export const minPasswordLength = 6
export const maxPasswordLength = 100

export const sportRadar = window.srtmCommands

export const isStatisticsAvailable = true

export const debugMode = process.env.NODE_ENV === 'development' || process.env.REACT_APP_BRAND_OVERWRITE
