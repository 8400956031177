// keep this file in js so storybook config can read it (it cannot read ts)
const backendUrl = process.env.REACT_APP_BACKEND_URL || ''
const apiUrl = `${backendUrl}/lotto-api/rest`
const paymentApiUrl = `${backendUrl}/payment-api/rest`
const desktopAppUrl = process.env.REACT_APP_DESKTOP_APP_URL ? process.env.REACT_APP_DESKTOP_APP_URL : 'http://127.0.0.1'
const defaultCashierPort = process.env.REACT_APP_DESKTOP_APP_DEFAULT_PORT
  ? process.env.REACT_APP_DESKTOP_APP_DEFAULT_PORT
  : '9000'

module.exports = {
  backendUrl,
  apiUrl,
  paymentApiUrl,
  desktopAppUrl,
  defaultCashierPort,
}
